import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/de'
import { StaticImage } from 'gatsby-plugin-image'

const lang = 'de'

export default class ContactPage extends React.Component {
  render() {
    const { content } = this.props.data.contact.translations.find(
      (t) => t.language === lang
    )
    const { location } = this.props

    return (
      <Layout location={location}>
        <div className="grid w-full h-full grid-cols-1 sm:grid-cols-2">
          <StaticImage
            className="w-full h-[200px] sm:h-[500px] self-center"
            src="../assets/img/contact-image.jpg"
            alt=""
          />
          <div className="flex flex-col justify-center w-full h-full p-10">
            <h1 className="mt-0 text-4xl font-bold font-title">Kontakt.</h1>
            <div className="bg-black h-[4px] w-[30px] mb-6"></div>
            <div
              className="font-text [&>p]:mb-2 font-bold"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    contact: directusPage(title: { eq: "Contact" }) {
      id
      title
      translations {
        content
        language
      }
    }
  }
`
